
export const EditIcon = (props) => {
    return (
        <div className='me-3' onClick={props.action ?? null}>
            <img src='images/editIcon.svg' alt='Edit Icon' role='button' />
        </div>
    )
}

export const DeleteIcon = (props) => {
    return (
        <div className='me-3' data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
            <img src='images/deleteIcon.svg' alt='Delete Icon' role='button' />
        </div>
    )
}