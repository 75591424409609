import React, { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import AccountList from '../modules/Account/AccountList';
import Dashboard from '../modules/Dashboard/Dashboard';

import Context from './Context';
import { fetchData } from './Helper';
import Layout from './Layout';
import NotFound from './NotFound';
import EditProfile from '../modules/Account/Editprofile';
import Systemlogs from '../modules/Account/Systemlogs';
import Login from '../modules/Auth/Login';
import Register from '../modules/Auth/Register';
import CreateTraining from '../modules/Training Provider/CreateTraining';
import TrainingList from '../modules/Training Provider/TrainingList';
import ChangePassword from '../modules/Auth/ChangePassword';
import ForgotPassword from '../modules/Auth/ForgotPassword';
import ResetPassword from '../modules/Auth/ResetPassword';
import VerifyEmail from '../modules/Auth/VerifyEmail';
import WebSettings from '../modules/Settings/WebSettings';
import ScheduleTimings from '../modules/Schedule/ScheduleTimings';
// import TrainingProvider from '../modules/Account/TrainingProvider';
import AccountDetails from '../modules/Account/AccountDetails';
import Gallery from '../modules/Gallery/gallery';
import Rating from '../modules/Rating/Rating';

export default function Routing() {
    const [context, setContext] = useState();
    const [token] = useState(localStorage.getItem('playzoon-token'));
    useEffect(() => {
        fetchData('get-site-data', 'GET', '', false, false, (res) => {
            if (res.status) {
                setContext(prevState => ({
                    ...prevState,
                    site: res.data
                }))
            }
        })

        if (token) {
            fetchData('get-auth-user', 'GET', '', true, false, (res) => {
                if (res.status) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.data,
                    }))
                }
            })
        }
    }, [token]);

    return (
        <>
            <Context.Provider value={[context, setContext]}>
                <Routes>
                    {/* Common routes */}
                    <Route path="/" exact caseSensitive={false} element={<Layout hideHeader={true} />}>
                        <Route path="/" caseSensitive={false} element={<Login />} />
                        <Route path="/login" caseSensitive={false} element={<Login />} />
                        <Route path="/register" caseSensitive={false} element={<Register />} />
                        <Route path="/forgot-password" caseSensitive={false} element={<ForgotPassword />} />
                        <Route path="/reset-password" caseSensitive={false} element={<ResetPassword />} />
                        <Route path="/verify-email" caseSensitive={false} element={<VerifyEmail />} />
                        <Route path="*" caseSensitive={false} element={<NotFound />} />
                    </Route>

                    <Route path="/" exact caseSensitive={false} element={<Layout hideHeader={false} />}>
                        <Route path="" caseSensitive={false} element={<Dashboard />} />
                        <Route path="dashboard" caseSensitive={false} element={<Dashboard />} />
                        <Route path="logs" caseSensitive={false} element={<Systemlogs />} > </Route>
                        <Route path="change-password" caseSensitive={false} element={<ChangePassword />} />
                        <Route path="my-profile" caseSensitive={false} element={<EditProfile />} />
                        <Route path="training" caseSensitive={false} element={<TrainingList />} />
                        <Route path="training/create" caseSensitive={false} element={<CreateTraining />} />
                        <Route path="training/edit/:id" caseSensitive={false} element={<CreateTraining />} />

                        <Route path="user-management/:account_type/accounts" caseSensitive={false} element={<AccountList />} />
                        {/* <Route path="/user-management/training-provider/accounts" caseSensitive={false} element={<TrainingProvider />} /> */}
                        <Route path="user-details/:id" caseSensitive={false} element={<AccountDetails />} />

                        <Route path="gallery" caseSensitive={false} element={<Gallery />} />
                        <Route path="rating" caseSensitive={false} element={<Rating />} />

                        <Route path="schedule-timings" caseSensitive={false} element={<ScheduleTimings />} />
                        <Route path="web-settings" caseSensitive={false} element={<WebSettings />} />
                        

                        <Route path="*" caseSensitive={false} element={<NotFound />} />
                    </Route>
                </Routes>
            </Context.Provider>
        </>
    )
}
