
import { BackendLayout } from '../../components/Layout';
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";

function AccountDetails() {

    return (
        <Container fluid>
            <BackendLayout title="User Details">
                <Row>
                    <Col>
                    <div className='accountDetailsTab'>
                        <Tabs
                            defaultActiveKey="Overview"
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                        >
                            <Tab eventKey="Overview" title="Overview">
                                <p>bmdbvndfvnv fn</p>
                            </Tab>
                            <Tab eventKey="Details" title="Details">
                                <p>hello</p>
                            </Tab>
                            <Tab eventKey="Orders" title="Orders">
                                <p>hiiiiiiiiii</p>
                            </Tab>
                            <Tab eventKey="Users" title="Users">
                                <p>ddddddddddddddddddd</p>
                            </Tab>
                            <Tab eventKey="Notifications" title="Notifications">
                                <p>ddddddddddddddddddd</p>
                            </Tab>
                        </Tabs>
                    </div>
                    </Col>
                </Row>
            </BackendLayout>
        </Container>
    )
}

export default AccountDetails