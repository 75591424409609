import { createRoot } from 'react-dom/client'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router';
import { TRAINING_LIST, TRAINING_STATUS } from "../../components/ApiRoutes";
import { fetchData, ConfirmationModal } from "../../components/Helper";
import Datatables, { loadDataTable, reloadUrlDataTable } from "../../components/Datatables";
import { DeleteButton, EditButton } from "../../components/ButtonHelper";

function TrainingList() {

    const navigate = useNavigate()
    const [deleteItem, setDeleteItem] = useState()
    
    const [dt] = useState({
        dt_url: TRAINING_LIST,
        dt_name: 'choice_list',
        dt_order: [[1, 'asc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", sortable: false, searchable: false },
            { data: "sports", name: 'sports', title: "Sports" },
            { data: "address", name: 'address', title: "Address" },
            { data: "image", name: 'image', title: "Image" },
            { data: "age_group", name: 'age_group', title: "Age Group" },
            { data: "gender", name: 'gender', title: "Gender" },
            { data: "club_affilation", name: 'club_affilation', title: "Club Affilation" },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                target: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.gender === 'male' ? 'Male' : 'Female'}
                    </>)
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "active" && <span className="text-green fw-medium" role="button" onClick={() => handleChangeStatus('inactive', records.id)}>Active</span>}
                        {records.status === "inactive" && <span className="text-danger fw-medium" role="button" onClick={() => handleChangeStatus('active', records.id)}>Inactive</span>}
                    </>)
                }

            },
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            <EditButton action={() => navigate(`/training/edit/${records.id}`)} />
                            <DeleteButton modal_id="#confirmationModal" action={() => setDeleteItem(records.id)} />
                        </div>
                    </>)
                }
            }
        ],
    })

    useEffect(() => {
        reloadUrlDataTable(dt, TRAINING_LIST)
    }, [dt])

    const handleChangeStatus = (status, id) => {
        fetchData(TRAINING_STATUS, 'POST', { 'status': status, 'id': id }, true, false, (res) => {
            loadDataTable(dt)
        }, false, false, false)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">Choice List</h6>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to={`/training/create`} className="btn btn-blue btn-sm px-3 me-2" title="Add Training"><i className="bi bi-plus-circle-fill me-1"></i> Add Training</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="choice_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmationModal action={() => handleChangeStatus('delete', deleteItem)} />
        </>
    )
}

export default TrainingList;