import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { fetchData, showPassword, validateForm } from '../../components/Helper'
import queryString from 'query-string';

const ResetPassword = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const [data, setData] = useState({
        password: '',
        password_confirmation: '',
    })

    useEffect(() => {
        if (location.search) {
            let query = queryString.parse(location.search);
            setData(({ password: '', password_confirmation: '', ...query }))
        } else {
            navigate('/')
        }
    }, [navigate, location])

    const handleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const sendData = (e) => {
        e.preventDefault()
        if (validateForm(e)) {
            fetchData('reset-password', 'POST', data, false, false, (res) => {
                if (res.status) {
                    setData({
                        password: '',
                        confirm_password: ''
                    })
                    localStorage.removeItem('playzoon-token')
                    navigate('/login')
                }
            })
        }
    }

    return (
        <section className="login-section">
            <div className="container">
                <div className="row g-0 rounded-15 overflow-hidden">
                <div className='col-md-6 col-lg-6 me-auto align-self-center'>
                        <div>
                            <img src='images/resetPasssword.svg' alt='Reset' className='w-100' />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 bg-white align-items-center align-self-center">
                        <div className='loginCardDiv'>
                            <form action="#" method="post" className="p-4 p-sm-5 w-100 needs-validation" noValidate>
                            <div className='text-center'>
                                    <h4 className="heading-lines headingMargin mb-3 mt-0">Reset Password</h4>
                                    <p className='mb-0 forgotPasswordRuningTxt'>Choose a new password for you account</p>
                                </div>
                                <div className=" mt-5 mb-3">
                                    <label htmlFor="floatingInput" className='loginLabelSpan'>Your new password</label>
                                    <input type="password" className="form-control loginSelectBox no-validate-icon" id="password" name="password" onChange={handleInputChange} placeholder="Your new password" autoComplete='off' required />
                                    <button type="button" onClick={(e) => showPassword(e, "password")} className="position-absolute me-3 top-0 end-0 pt-3">
                                        <i className="bi-eye fs-5 pe-none"></i>
                                    </button>
                                </div>
                                <div className=" mb-3">
                                    <label htmlFor="floatingInput" className='loginLabelSpan'>Confirm your new password</label>
                                    <input type="password" className="form-control loginSelectBox no-validate-icon" id="password_confirmation" name="password_confirmation" onChange={handleInputChange} placeholder="Confirm your new password" autoComplete='off' required />
                                    <button type="button" onClick={(e) => showPassword(e, "password_confirmation")} className="position-absolute me-3 top-0 end-0 pt-3">
                                        <i className="bi-eye fs-5 pe-none"></i>
                                    </button>
                                </div>
                                <div className="mb-4">
                                    <button type="submit" onClick={sendData} className="btn btn-blue btn-lg w-100" title='RESET'>Reset Password</button>
                                </div>
                                <div className="d-flex justify-content-between pb-3">
                                    <Link to={`/login`} className="anchor-blueorange fw-medium" title='Login'>Login</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>
    )
}

export default ResetPassword
