import { useContext, useEffect, useState } from "react";
import { ACCOUNT_UPDATE } from "../../components/ApiRoutes";
import { InputField, SelectField, SubmitButton, SubmitButtonNew, TextArea } from "../../components/FormHelper";
import { fetchData, validateForm } from "../../components/Helper";
import Context from "../../components/Context";
import { GET_PROFILE } from "../../components/ApiRoutes";
import { Row, Col } from "react-bootstrap";

function Editprofile() {
    const [context, setContext] = useContext(Context);
    const userId = context && context.auth && context.auth.id;
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState()

    useEffect(() => {
        if (userId) {
            fetchData(`${GET_PROFILE}/${userId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                }
            })
        }
    }, [userId])

    const updateAccount = (e) => {

        if (validateForm(e)) {
            setTinyloader(true)
            let api_url = ACCOUNT_UPDATE;
            let formdata = new FormData(document.getElementById('accountupdatingForm'))

            if (userId) {
                api_url = ACCOUNT_UPDATE
                formdata.append('user_id', userId);
                formdata.append('profile_update', true);

            }

            fetchData(api_url, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: { ...context.auth, profile_image: res.data.profile_image }
                    }))
                }
            })

        }
    }
    return (

        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-3">
                        <h6 className="m-0 heading-lines fw-medium pb-2">My Profile</h6>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            {/* <div className="card-body p-sm-4 p-xl-5">
                                <form id="accountupdatingForm" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    <InputField name="name" value={data && data.name} required />
                                    <InputField name="email" value={data && data.email} disabled={userId} required />
                                    <InputField type="number" name="phone" value={data && data.phone} />
                                    <div className="mb-4 row mx-0">
                                        <div className="col-sm-3 col-lg-2 col-form-label">
                                            <label class="form-check-label me-2" for=""> Gender</label>
                                        </div>
                                        <div className="col-sm-9 col-lg-10 col-xxl-6 align-self-center">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="gender" id="inlineRadio1" value="male" checked />
                                                <label class="form-check-label" for="inlineRadio1">Male</label>
                                            </div>
                                            <div class="form-check form-check-inline ">
                                                <input class="form-check-input" type="radio" name="gender" id="inlineRadio2" value="female" />
                                                <label class="form-check-label" for="inlineRadio2">Female</label>
                                            </div>
                                        </div>
                                    </div>
                                    <TextArea name="Biography" />
                                    <InputField type="file" name="profile_image" />
                                    <InputField className="mt-4 l" type="text" name="Experience" value={data && data.experience} />
                                    <SubmitButton load={tinyloader} title={userId ? "Update" : "Save"} action={updateAccount} />
                                </form>
                            </div> */}
                            <div>
                                <form id="accountupdatingForm" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    <div className="d-flex">
                                        <div className="upload-btn-wrapper position-relative py-3">
                                            <button class="Uploadbtn">Upload a file</button>
                                            <input type="file" className="fileUploader" name="profile_image" />
                                            <img src="/images/change photo.svg" className="cameraImg" />
                                        </div>
                                        <div className="ms-5 align-self-center">
                                            <p className="mb-0 mt-5 profileName">Mobina Mirbagheri</p>
                                        </div>
                                    </div>
                                    <Row className="ps-lg-5 mt-lg-5">
                                        <Col md={6} lg={6} xs={12}>
                                            <p className="mb-0 profileDetailsHeading">Personal</p>
                                            <Row className="mt-4">
                                                <Col lg={6} md={6} xs={12}>
                                                    <div>
                                                        <div className="datePickerDiv">
                                                            <label for="start_time" >First Name<sup className='text-danger fw-bold fs-15px'></sup></label>
                                                            <input type="text" className="form-control scheduleTimeDatePicker" name="" placeholder="Enter Name" />
                                                            <span className="text-success fs-12px"></span>
                                                            {/* <div className="validationTxt">The field is required.</div> */}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} xs={12}>
                                                    <div>
                                                        <div className="datePickerDiv">
                                                            <label for="start_time" >Surname<sup className='text-danger fw-bold fs-15px'></sup></label>
                                                            <input type="text" className="form-control scheduleTimeDatePicker" name="" placeholder="Enter Surname" />
                                                            <span className="text-success fs-12px"></span>
                                                            {/* <div className="validationTxt">The field is required.</div> */}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mt-4">
                                                        <div className="datePickerDiv">
                                                            <label for="start_time" >Gender<sup className='text-danger fw-bold fs-15px'></sup></label>
                                                            <input type="email" className="form-control scheduleTimeDatePicker" name="" placeholder="Enter Value" />
                                                            <span className="text-success fs-12px"></span>
                                                            {/* <div className="validationTxt">The field is required.</div> */}
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="datePickerDiv">
                                                            <label for="start_time" >Biography<sup className='text-danger fw-bold fs-15px'></sup></label>
                                                            <input type="text" className="form-control scheduleTimeDatePicker" name="" placeholder="Enter Value" />
                                                            <span className="text-success fs-12px"></span>
                                                            {/* <div className="validationTxt">The field is required.</div> */}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} lg={6} xs={12}>
                                            <p className="mb-0 profileDetailsHeading ps-lg-5">Contact</p>
                                            <div className="mt-4 ps-lg-5">
                                                <div className="datePickerDiv">
                                                    <label for="start_time" >Email<sup className='text-danger fw-bold fs-15px'></sup></label>
                                                    <input type="email" className="form-control scheduleTimeDatePicker" name="" placeholder="Enter Value" />
                                                    <span className="text-success fs-12px"></span>
                                                    {/* <div className="validationTxt">The field is required.</div> */}
                                                </div>
                                            </div>
                                            <div className="mt-4 ps-lg-5">
                                                <div className="datePickerDiv">
                                                    <label htmlFor="" >Phone
                                                        <sup className='text-danger fw-bold fs-15px'></sup>
                                                    </label>
                                                    <input type="number" className="form-control scheduleTimeDatePicker" placeholder="Enter Number" />
                                                    <span className="text-success fs-12px"></span>
                                                    {/* <div className="validationTxt invalid-feedback">The {_.startCase(props.name)} field is required.</div>  */}
                                                </div>
                                            </div>
                                            <div className="mt-4 ps-lg-5">
                                                <div className="datePickerDiv">
                                                    <label for="start_time" >Experience<sup className='text-danger fw-bold fs-15px'></sup></label>
                                                    <input type="text" className="form-control scheduleTimeDatePicker" name="" placeholder="Software" />
                                                    <span className="text-success fs-12px"></span>
                                                    {/* <div className="validationTxt">The field is required.</div> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ps-lg-5 mt-5">
                                            <SubmitButtonNew load={tinyloader} title={userId ? "Update" : "Save"} action={updateAccount} />
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Editprofile;