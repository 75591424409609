import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Aside from "./Aside";
import { useContext, useEffect } from "react";
import Context from "./Context";
import NotFound from "./NotFound";

export const Layout = (props) => {
    const navigate = useNavigate()
    const [context] = useContext(Context)

    useEffect(() => {
        let token = localStorage.getItem('playzoon-token')
        if (!token && !props.hideHeader) {
            navigate('/login')
        }
    }, [navigate, props.hideHeader]);

    return (
        <>
            {!props.hideHeader && <Header />}
            {!props.hideHeader && <Aside />}
            {props.hideHeader ? <Outlet /> : <>
                {(context && context.auth) ? <Outlet /> : <NotFound />}
            </>}
        </>
    )
}

const BackendLayout = (props) => {

    return (
        <section className="admin-wrapper">
            <div className="container-fluid pe-0">
                <div className="card-new h-100 p-4">
                    <div className="row justify-content-between">
                        <div className="col mb-3 align-self-center">
                            <h6 className="m-0 heading-lines fw-medium pb-2">{props.title ?? ""}</h6>
                        </div>
                    </div>
                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Layout;
export { BackendLayout };