// Account Routes

export const ACCOUNT_LIST = "account/lists"
export const ACCOUNT_CREATE = "account/create"
export const ACCOUNT_EDIT = "account/edit"
export const ACCOUNT_UPDATE = "account/update"
export const ACCOUNT_CHANGE_STATUS = "account/change-status"
export const LOGOUT = "logout"
export const GET_ACTIVE_PROJECT_MANAGER = "get-active-project-manager"
export const GET_ACTIVE_CLIENT = "get-active-client"
export const GET_ACTIVE_PROJECT = "get-active-project"
export const GET_PROFILE = "get-profile"
export const CHANGE_PASSWORD = "change-password"
export const GENERATE_DRIVE_TOKEN = "account/generate-drive-token"
export const MULTIPLE_ACCOUNT_DELETE = "account/multiple-delete"

//Training Management
export const TRAINING_CREATE = 'training/create'
export const TRAINING_LIST = 'training/lists'
export const TRAINING_STATUS = 'training/change-status'

//Login
export const LOGIN = "login" 
export const SOCIAL_LOGIN = "social-login" 

//Register
export const REGISTER = "register"

//Role
export const ROLE_LIST = "role-list"

// Dashboard Routes

export const DASHBOARD_STATS = "statistics"



//Permission
export const PERMISSION = "account/permission"

//LOG  URL
export const LOG_URL = "account/log"

//WEATEHER
export const WEATHER_INFO = 'get-weather-report' 

//SETTINGS
export const GET_SETTINGS = 'get-settings' 
export const ADD_SETTINGS = 'add-setting' 
export const REMOVE_SETTINGS = 'remove-setting' 
export const UPDATE_SETTINGS = 'update-settings' 

//SCHEDULE TIMINGS
export const SCHEDULE_LIST = 'suchedule/list' 
export const SCHEDULE_CREATE = 'suchedule/create' 
export const SCHEDULE_EDIT = 'suchedule/edit' 
export const SCHEDULE_UPDATE = 'suchedule/update' 
export const SCHEDULE_DELETE = 'suchedule/delete' 