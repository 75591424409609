import { useState, useEffect } from "react"
import { fetchData, initialFormState, validateForm } from "../../components/Helper"
import { ADD_SETTINGS, GET_SETTINGS, REMOVE_SETTINGS } from "../../components/ApiRoutes";

function WebSettings() {
    const [reload, setReload] = useState(0);
    const [setting, setSetting] = useState([])
    const [data, setData] = useState({
        value: '',
        slug: ''
    })
    useEffect(() => {
        fetchData(`${GET_SETTINGS}?type=web`, 'get', '', true, false, (res) => {
            setSetting(res.data)
        })
    }, [reload])

    const handleInputChange = (e) => {
        document.getElementsByClassName(`${e.target.name}-error`)[0].classList.add('d-none')
        setData(prevState => ({
            ...prevState,
            value: e.target.value,
            slug: e.target.id
        }))
    }
    const handleSubmitForm = (e, id) => {
        if (validateForm(e, id)) {
            fetchData(`${ADD_SETTINGS}?type=web`, 'POST', data, true, false, (res) => {
                if (res.status) {
                    initialFormState(id)
                    setReload(reload + 1)
                }
            })
        }
    }

    const removeVehicleSetting = (value, slug) => {
        fetchData(`${REMOVE_SETTINGS}?type=web`, 'POST', { slug: slug, value: value }, true, false, (res) => {
            if (res.status) {
                setReload(reload + 1)
            }
        })
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-3">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Web Settings</h5>
                        </div>
                    </div>
                    {setting && setting.length > 0 && setting.map((item, index) => {
                        return (
                            <div className="row m-0 mb-3" key={index} style={{ 'border': '1px solid #e9e9ef' }}>
                                <div className="col-sm-12 p-3 p-sm-4">
                                    <label htmlFor='' className="mb-1">{item.name}</label>
                                    <form className="input-group mb-3 maxw-380px needs-validation" id={'setform' + item.slug} noValidate>
                                        <input type="text" className="form-control" placeholder="" id={item.slug} name="value" autoComplete="off" required onChange={handleInputChange} />
                                        <button className="btn btn-primary" type="button" onClick={(e) => handleSubmitForm(e, `setform${item.slug}`)} id="addmore">
                                            Add
                                        </button>
                                    </form>
                                    <div className="error value-error small text-danger px-2 py-1 d-none"></div>
                                    <div className="d-flex flex-wrap" id="vehicleBadge">
                                        {item.value.split(',').map((value) => {
                                            return (
                                                <div key={value} className="d-inline-flex align-items-center bg-light border rounded-pill ps-3 pe-2 py-1 mb-2 me-2">
                                                    <div className="fw-medium me-3 opacity-85">{value}</div>
                                                    <button type="button" onClick={() => removeVehicleSetting(value, item.slug)} className="btn-close ms-auto fs-12px" aria-label="Close"></button>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
        </>
    )
}

export default WebSettings