import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "./ApiRoutes";
import Context from './Context'
import { fetchData, noImageHandle } from "./Helper";

const Header = () => {
    const [context, setContext] = useContext(Context)
    const navigate = useNavigate()

    useEffect(() => {
        document.querySelectorAll("#menuToggle, #menuClose").forEach(function (el) {
            el.addEventListener('click', () => {
                document.body.classList.toggle('menu-open');
            })
        });
    }, [])

    const handleLogout = () => {
        fetchData(LOGOUT, 'POST', '', true, false, (res) => {
            if (res.status) {
                setContext(prevState => ({
                    ...prevState,
                    auth: ''
                }))
                localStorage.removeItem("playzoon-token");
                navigate("/login")
            }
        })
    }

    const navigateTo = (url) => {
        navigate(url)
    }
    return (
        <header className="admin-header">
            <div className="row m-0 h-100 align-items-center">
                <div className="col-auto">
                    <button type="button" className="btn mmwh-36px bg-white p-0 d-flex align-items-center justify-content-center" id="menuToggle" title="Menu Toggle">
                        <i className="bi-list bi-8x lh-1"></i>
                    </button>
                </div>
                <div className="col-auto m-auto position-relative">
                    <img src="/images/SearchIcon.svg" className="searchIconImg" />
                    <input className="form-control headerSearchaBar" type="text" placeholder="Search here..." aria-label="Search" />
                </div>
                <div className="col-auto ms-auto">
                    <div className="dropdown dropdown-orange d-inline-block align-middle">
                        <button type="button" className="admin-profile" data-bs-toggle="dropdown">
                            {context && context.auth && <img
                                src={process.env.REACT_APP_ASSET_URL + context.auth.profile_image}
                                // src="images/Icon.svg"
                                alt="profile" onError={noImageHandle} />}
                            <div className="ms-2">
                                <h5>{context && context.auth && context.auth.name}</h5>
                            </div>
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end shadow border-0 p-2">
                            <li><button type="button" className="dropdown-item rounded my-1px" onClick={() => navigateTo("/my-profile")} title="Profile">My Profile</button></li>
                            <li><button type="button" className="dropdown-item rounded my-1px" onClick={() => navigateTo("/change-password")} title="Change Password">Change Password</button></li>
                            <li><button type="button" className="dropdown-item rounded my-1px" onClick={handleLogout} title="Logout">Logout</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header