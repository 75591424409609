import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TRAINING_CREATE, GET_SETTINGS } from "../../components/ApiRoutes";
import { InputField, SubmitButton } from "../../components/FormHelper";
import { fetchData, validateForm } from "../../components/Helper";
import Context from "../../components/Context";


function CreateTraining() {
    const navigate = useNavigate()
    const trainingId = useParams().id
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState();
    const [ageGroup, setAgeGroup] = useState([]);
    const [game, setGame] = useState([]);
    const [inputGame, setInputGame] = useState();
    const [inputAgeGroup, setInputAgeGroup] = useState();

    const updateAccount = (e) => {

        if (validateForm(e)) {
            setTinyloader(true)

            let formdata = new FormData(document.getElementById('trainingForm'))
            let apiUrl = trainingId ? TRAINING_CREATE + '/' + trainingId : TRAINING_CREATE

            formdata.append('sports', inputGame)
            formdata.append('age_group', inputAgeGroup)
            fetchData(apiUrl, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate('/training')
                }
            })
        }
    }

    useEffect(() => {
        if (trainingId) {
            fetchData(`${TRAINING_CREATE}/${trainingId}`, 'GET', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                    setInputGame(res.data && res.data.sports)
                    setInputAgeGroup(res.data && res.data.age_group)
                }
            })
        }
    }, [trainingId])

    useEffect(() => {

        fetchData(`${GET_SETTINGS}?type=web`, 'GET', '', true, false, (res) => {
            if (res.status) {
                res.data.forEach((ele) => {
                    if (ele.slug === 'age_group') {
                        setAgeGroup(ele.value.split(','))
                    }

                    if (ele.slug === 'game') {
                        setGame(ele.value.split(','))
                    }
                })
            }
        })
    }, [])

    return (

        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-3">
                        <h6 className="m-0 heading-lines fw-medium pb-2">Training Management</h6>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5 ">
                                <form id="trainingForm" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    {/* <InputField name="sports" value={data && data.sports} required /> */}

                                    <div className="form-floating mb-3">
                                        <select className="form-select" aria-label="Default select example" value={inputGame} id="sports" name="sports" onChange={(e) => setInputGame(e.target.value) } required>
                                            <option value="">Select Game</option>
                                            {game && game.length > 0 && game.map((ele, key) => <option value={ele} key={key}>{ele}</option>)}
                                        </select>
                                    </div>
                                    {/* <InputField name="age_group" value={data && data.age_group} required /> */}
                                    <div className="form-floating mb-4 row mx-0">
                                        <select className="form-select" aria-label="Default select example" value={inputAgeGroup} id="age_group" name="age_group" onChange={(e) => setInputAgeGroup(e.target.value) } required>
                                            <option value="">Select Age Group</option>
                                            {ageGroup && ageGroup.length > 0 && ageGroup.map((ele, key) => <option value={ele} key={key}>{ele}</option>)}
                                        </select>
                                    </div>

                                    <InputField name="club" value={data && data.club} required />
                                    <InputField name="rate" value={data && data.rate} required />
                                    <InputField type="number" value={data && data.total_players} name="total_player" />
                                    <div className="mb-4 row mx-0">
                                        {data && data.gender ?
                                            <div className="col-sm-9 col-lg-6 col-xxl-5 flex justify-between m-auto">
                                                <label className="form-check-label me-2" htmlFor=""> Gender</label>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="gender" id="inlineRadio1" value="male" defaultChecked={data.gender === 'male' ? true : false} />
                                                    <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                                                </div>
                                                <div className="form-check form-check-inline ">
                                                    <input className="form-check-input" type="radio" name="gender" id="inlineRadio2" value="female" defaultChecked={data.gender === 'female' ? true : false} />
                                                    <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-sm-9 col-lg-6 col-xxl-5 flex justify-between m-auto">
                                                <label className="form-check-label me-2" htmlFor=""> Gender</label>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="gender" id="inlineRadio1" value="male" defaultChecked />
                                                    <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                                                </div>
                                                <div className="form-check form-check-inline ">
                                                    <input className="form-check-input" type="radio" name="gender" id="inlineRadio2" value="female" />
                                                    <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="address" className="col-sm-3 col-xxl-2 col-form-label">Address<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <textarea className="form-control" defaultValue={data && data.address} id="address" name="address" rows="3"></textarea>
                                        </div>
                                    </div>
                                    <InputField name="training_image" type="file" accept=".jpg,.jpeg,.png"/>
                                    <SubmitButton load={tinyloader} title={trainingId ? "Update" : "Save"} action={updateAccount} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateTraining;