import { createRoot } from 'react-dom/client'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { ButtonSpinner, ConfirmationModal, fetchData, initialFormState, validateForm } from "../../components/Helper";
import { SCHEDULE_CREATE, SCHEDULE_LIST } from "../../components/ApiRoutes";
import moment from 'moment'
import { Col, Container, Form, Row } from "react-bootstrap";
import { useEffect } from 'react';
import DataTables, { loadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { DeleteIcon, EditIcon } from '../../components/ActionIcons';
import { BackendLayout } from '../../components/Layout';
import _ from 'lodash';
import { SCHEDULE_DELETE } from '../../components/ApiRoutes';
import { SCHEDULE_UPDATE } from '../../components/ApiRoutes';


function ScheduleTimings() {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [day, setDay] = useState('');
    const [days] = useState(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);
    const [tinyloader, setTinyloader] = useState(null);
    const [id, setId] = useState();
    const [edit, setEdit] = useState(false);

    const [dt] = useState({
        dt_url: `${SCHEDULE_LIST}`,
        dt_name: 'schedule_list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false },
            { data: "day", name: 'day', title: "Day" },
            { data: "start_time", name: 'start_time', title: "In - Time" },
            { data: "end_time", name: 'end_time', title: "Out - Time" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(_.startCase(records.day))
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className='d-flex justify-content-center'>
                            <EditIcon action={() => editTiming(records)} />
                            <DeleteIcon modal_id="#scheduleDeleteModal" action={() => setId(records.id)} />
                        </div>
                    </>)
                }
            }
        ],
        dt_filter: () => {
            createRoot(document.getElementById("dt-custom-button")).render(
                <Form.Select aria-label="Default select example" className="scheduleTimeSelect" onChange={filterTable} required>
                    <option value={""}>Select Day</option>
                    {days.map((day, key) => <option key={key} value={day}>{_.startCase(day)}</option>)}
                </Form.Select>
            )
        }
    })

    useEffect(() => {
        reloadUrlDataTable(dt, `${SCHEDULE_LIST}`)
    }, [dt])

    const filterTable = (e) => {
        reloadUrlDataTable(dt, `${SCHEDULE_LIST}?day=${e.target.value}`)
    }

    const addTiming = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            fetchData(edit ? `${SCHEDULE_UPDATE}/${id}` : SCHEDULE_CREATE, 'POST', { day, start_time: moment(startTime).format("h:mm A"), end_time: moment(endTime).format("h:mm A") }, true, false, (res) => {
                setTinyloader(false)
                if (res.status) {
                    loadDataTable(dt)
                    initialData()
                }
            })
        }
    }

    const editTiming = (data) => {
        setStartTime(new Date(data.in_time))
        setEndTime(new Date(data.out_time))
        setDay(data.day)
        setId(data.id)
        setEdit(true)
    }

    const initialData = () => {
        initialFormState("scheduleCreateForm")
        setStartTime(null)
        setEndTime(null)
        setDay('')
        setEdit(false)
    }

    const deleteTiming = () => {
        fetchData(`${SCHEDULE_DELETE}/${id}`, 'POST', '', true, false, (res) => {
            if (res.status) loadDataTable(dt)
        })
    }


    return (
        <Container fluid>
            <BackendLayout title="Schedule List">
                <Row>
                    <Col md={5} lg={3} xs={12}>
                        <div className="cardGray text-center p-4">
                            <div className="createScheduleBtn">
                                {edit ? "Update Schedule" : "Create Schedule"}
                            </div>
                            <form id="scheduleCreateForm" action="#" method="post" className="needs-validation" noValidate>
                                <div style={{ marginTop: "45px" }}>
                                    <Form.Select value={day} aria-label="Default select example" className="scheduleTimeSelect" onChange={(e) => setDay(e.target.value)} required>
                                        <option value={""}>Select Day</option>
                                        {days.map((day, key) => <option key={key} value={day}>{_.startCase(day)}</option>)}
                                    </Form.Select>
                                </div>

                                <div style={{ marginTop: "45px" }}>
                                    <div className="datePickerDiv">
                                        <label htmlFor="start_time" >In - Time</label>
                                        <DatePicker
                                            selected={startTime}
                                            onChange={(date) => setStartTime(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="In - Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="In - Time"
                                            autoComplete='off'
                                            className="form-control scheduleTimeDatePicker"
                                            onChangeRaw={(e) => e.preventDefault()}
                                            id="start_time"
                                            name="start_time"
                                            required
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: "45px" }}>
                                    <div className="datePickerDiv">
                                        <label htmlFor="start_time" >Out - Time</label>
                                        <DatePicker
                                            selected={endTime}
                                            onChange={(date) => setEndTime(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Out - Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="Out - Time"
                                            autoComplete='off'
                                            className="form-control scheduleTimeDatePicker"
                                            onChangeRaw={(e) => e.preventDefault()}
                                            id="end_time"
                                            name="end_time"
                                            required
                                        />
                                    </div>
                                </div>
                            </form>
                            <div className="scheduleTimeLeftBottomBtnFixed">
                                <button className="createScheduleBtnNew" onClick={addTiming}>
                                    <ButtonSpinner load={tinyloader} btnName={edit ? "Update Schedule" : "Create Schedule"} />
                                </button>
                                <button className="createScheduleBtnNew mt-2" onClick={initialData}>
                                    Cancle
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col md={7} lg={9} xs={12}>
                        <DataTables dt_name="schedule_list" />
                    </Col>
                </Row>
                <ConfirmationModal modal_id="scheduleDeleteModal" title="Delete Schedule" action={deleteTiming}>
                    <p className="">Are you sure want to delete this Schedule?</p>
                </ConfirmationModal>
            </BackendLayout>
        </Container>

    )
}

export default ScheduleTimings