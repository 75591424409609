import { useLocation } from 'react-router';
import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import Context from "./Context";

const Aside = () => {
    const [context] = useContext(Context)
    const location = useLocation()

    return (
        <aside className="admin-aside">
            <div className="admin-logobox d-block">
                <Link to="/" className="admin-brandbox text-center" title="Playzoon">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2">
                            <i className="bi bi-house-door-fill"></i>
                        </span> Playzone
                    </h3>
                </Link>
                <button type="button" className="btn btn-close mmwh-36px border-0 p-0 align-items-center justify-content-center" id="menuClose" title="Menu Close"></button>
            </div>

            <div className="admin-navbox" id="navtoggle">
                <div className="accordion-item">
                    <NavLink className={location.pathname.match('/dashboard') || (location.pathname === '/') ? "main-link active collapsed" : "main-link collapsed"} to="/dashboard" title="Dashboard">
                        <img src="/images/Dicon.svg" alt="playzoon - Logo" />
                        Dashboard
                    </NavLink>
                </div>

                {context && context.auth && context.auth.role_id === 1 && <>
                    <div className="accordion-item">
                        <a className={location.pathname.match('/user-management/*') ? "main-link" : "main-link collapsed"} data-bs-toggle="collapse" href="#submenu1" aria-expanded={location.pathname.match('/user-management/*') ? 'true' : 'false'} title="User Management">
                            <img src="/images/MIcon.svg" alt="playzoon - Logo" />
                            User Management
                        </a>
                        <div id="submenu1" className={location.pathname.match('/user-management/*') ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} data-bs-parent="#navtoggle">
                            <NavLink className={location.pathname.match('/user-management/coach/*') ? 'sub-link active' : 'sub-link'} to="/user-management/coach/accounts" title="Coach">Coach</NavLink>
                            <NavLink className={location.pathname.match('/user-management/training-provider/*') ? 'sub-link active' : 'sub-link'} to="/user-management/training-provider/accounts" title="Training Provider">Training Provider</NavLink>
                            <NavLink className={location.pathname.match('/user-management/facility-provider/*') ? 'sub-link active' : 'sub-link'} to="/user-management/facility-provider/accounts" title="Facility Provider">Facility Provider</NavLink>
                            <NavLink className={location.pathname.match('/user-management/user/*') ? 'sub-link active' : 'sub-link'} to="/user-management/user/accounts" title="User">User</NavLink>
                        </div>
                    </div>
                </>}

                {context && context.auth && context.auth.role_id && context.auth.role_id === 3 && <>
                    <div className="accordion-item">
                        <a className={(location.pathname.match('/training/*')) ? "main-link" : "main-link collapsed"} data-bs-toggle="collapse" href="#submenu3" aria-expanded={(location.pathname.match('/training/*')) ? 'true' : 'false'} title="Training Management">
                            <img src="/images/traningManagment.svg" alt="playzoon - Logo" />
                            Training Management
                        </a>
                        <div id="submenu3" className={(location.pathname.match('/training/*')) ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} data-bs-parent="#navtoggle">
                            <NavLink className={location.pathname.match('/training/*') ? 'sub-link active' : 'sub-link'} to="/training" title="Choice List">Training</NavLink>
                        </div>
                    </div>
                </>}

                {context && context.auth && context.auth.role_id && [2, 3, 4].includes(context.auth.role_id) && <>
                    <div className="accordion-item">
                        <NavLink className={location.pathname.match('/schedule-timings') ? "main-link  collapsed" : "main-link collapsed"} to="/schedule-timings" title="Schedule Timings">
                            <img src="/images/calendarIcon.svg" alt="playzoon - Logo" />
                            Schedule Timings
                        </NavLink>
                    </div>
                    <div className="accordion-item">
                        <NavLink className={location.pathname.match('/gallery') ? "main-link  collapsed" : "main-link collapsed"} to="/gallery" title="Schedule Timings">
                            <img src="/images/calendarIcon.svg" alt="playzoon - Logo" />
                            Gallery
                        </NavLink>
                    </div>
                </>}

                {context && context.auth && context.auth.role_id === 1 && <>
                    <div className="accordion-item">
                        <NavLink className={location.pathname.match('/logs') ? "main-link  collapsed" : "main-link collapsed"} to="/logs" title="Activity Logs">
                            <img src="/images/Chart_Line.svg" alt="playzoon - Logo" />
                            Activity Logs
                        </NavLink>
                    </div>
                </>}

            </div>

            <div className="admin-copyrightsbox">{context && context.site && context.site.copyright} | v1.0.0</div>
        </aside>
    )
}

export default Aside
