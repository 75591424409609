import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchData, initialFormState, scrollbarSetToTop, validateForm } from '../../components/Helper'
import { ROLE_LIST } from '../../components/ApiRoutes'
import Context from '../../components/Context'

const ForgotPassword = () => {

    const [context] = useContext(Context)
    const [token] = useState(localStorage.getItem('playzoon-token'))
    const [email, setEmail] = useState("")
    const [role, setRole] = useState("");
    const [roleList, setRoleList] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        scrollbarSetToTop();
        if (token && context && context.auth) {
            navigate("/dashboard");
        }
    }, [token, context, navigate])

    useEffect(() => {
        fetchData(`${ROLE_LIST}?all=yes`, 'GET', '', false, false, (res) => {
            if (res.status) {
                setRoleList(res.data)
            }
        })
    }, [])

    const sendData = (e) => {
        e.preventDefault()
        if (validateForm(e)) {
            fetchData('forgot-password', 'POST', { role_id: role, email: email }, false, false, (res) => {
                if (res.status) {
                    setEmail("");
                    initialFormState("forgot_password")
                }
            })
        }
    }
    return (

        <section className="login-section position-relative">
            <div className="container">
                <div className="row g-0 rounded-10 overflow-hidden">
                    <div className='col-md-6 col-lg-6 me-auto align-self-center'>
                        <div>
                            <img src='images/forgotPasswordImg.svg' alt='Forgot' className='w-100' />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 bg-white align-items-center">
                        <div className='loginCardDiv'>
                            <form action="#" method="post" className="p-4 p-sm-5 w-100 needs-validation" id="forgot_password" noValidate>
                                <div className='text-center'>
                                    <h4 className="heading-lines headingMargin mb-3 mt-0">Forgot Password</h4>
                                    <p className='mb-0 forgotPasswordRuningTxt'>Enter the email you used to create your account so we can send you instructions on how to reset your password.</p>
                                </div>
                                <div className="form-floating mb-3 mt-5">
                                    <span className='loginLabelSpan'>Select</span>
                                    <select className="form-select loginSelectBox" aria-label="Default select example" id="role_id" name="role_id" value={role} required onChange={(e) => setRole(e.target.value)}>
                                        <option value="">Select</option>
                                        {roleList && roleList.length > 0 && roleList.map((role, key) => <option value={role.id} key={key}>{role.name}</option>)}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className='loginLabelSpan'>Email</label>
                                    <input type="text" className="form-control loginSelectBox" id="email" name="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
                                </div>
                                <div className="mb-4 mt-5">
                                    <button type="submit" onClick={sendData} className="btn btn-blue btn-lg w-100" title='SUBMIT'>Send</button>
                                </div>
                                <div className="d-flex justify-content-between pb-3">
                                    <Link to={`/login`} className="anchor-blueorange fw-medium" title='Login'>Login</Link>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    )
}

export default ForgotPassword
