import { Container, Row } from "react-bootstrap";
import { BackendLayout } from '../../components/Layout';

function Gallery() {


    return (
        <Container fluid>
            <BackendLayout title="Gallery">
                <Row>
                    Gallery
                </Row>
            </BackendLayout>
        </Container>
    )
}

export default Gallery