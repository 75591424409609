import { Container, Row } from "react-bootstrap";
import { BackendLayout } from '../../components/Layout';

function Rating() {


    return (
        <Container fluid>
            <BackendLayout title="Rating">
                <Row>
                    Rating
                </Row>
            </BackendLayout>
        </Container>
    )
}

export default Rating