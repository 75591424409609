import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Context from "./Context";

function NotFound() {
    const [title, setTitle] = useState('Loading...')
    const [subTitle, setSubTitle] = useState('Please wait...')
    const [context] = useContext(Context)
    const [token] = useState(localStorage.getItem('playzoon-token'));
    const location = useLocation()

    useEffect(() => {
        if (location.pathname.match('/*')) {
            if (token) {
                if (context && context.auth) {
                    setTitle('404')
                    setSubTitle('Opps! Page Not Found')
                }
            } else {
                setTitle('404')
                setSubTitle('Opps! Page Not Found')
            }
        } else {
            setTitle('404')
            setSubTitle('Opps! Page Not Found')
        }
    }, [token, location, context])
    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <section className="d-flex align-items-center align-middle p-5 w-100 start-0 top-0 h-100">
                    <style jsx="true">{`
                        h1{
                            font-size: calc(  ${title === '404' ? 7 : 0}vw + 100px);
                        }
                        h5{
                            font-size: calc( 1vw + 16px);
                        }
                    `}</style>
                    <div className="col-sm-12 text-center">
                        <h1 className="fw-bolder my-5" style={{color: "#F8BF86"}}>{title}</h1>
                        <h5 className="fw-bolder my-5" style={{color: "#F8BF86"}}>{subTitle}</h5>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default NotFound;