import { createRoot } from 'react-dom/client'
import { useEffect, useState } from "react";
import { useParams } from 'react-router';
import _ from 'lodash';
import Datatables, { loadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { ActiveButton, DeactiveButton, DeleteButton, EditButton, VerifyButton, ViewButton } from '../../components/ButtonHelper';
import { ButtonSpinner, ConfirmationModal, fetchData, initialFormState, validateForm } from '../../components/Helper';
import { ACCOUNT_CHANGE_STATUS, ACCOUNT_CREATE, ACCOUNT_LIST, ACCOUNT_UPDATE } from '../../components/ApiRoutes';
import { BackendLayout } from '../../components/Layout';
import { Col, Container, Row } from "react-bootstrap";
import { InputField, PasswordField } from '../../components/FormHelper';
import { useNavigate } from 'react-router-dom'

function AccountList() {
    const accountType = useParams().account_type
    const [userId, setUserId] = useState()
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState({})
    const navigation = useNavigate()

    const [dt] = useState({
        dt_url: `${ACCOUNT_LIST}?type=${accountType}`,
        dt_name: 'account_list',
        dt_order: [[1, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false },
            { data: "name", name: 'name', title: "Name" },
            { data: "email", name: 'email', title: "Email" },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "active" && <p className="text-green fw-medium">Active</p>}
                        {records.status === "inactive" && <p className="text-danger fw-medium">Inactive</p>}
                        {records.status === "pending" && <p className="text-warning fw-medium">Pending</p>}
                    </>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {records.status === "pending" && <VerifyButton action={() => handleChangeStatus('verify-email', records.id)} />}
                            {records.status === "inactive" && <ActiveButton action={() => handleChangeStatus('active', records.id)} />}
                            {records.status === "active" && <DeactiveButton action={() => handleChangeStatus('inactive', records.id)} />}
                            <EditButton action={() => editUser(records)} />
                            <ViewButton action={() => navigation(`/user-details/${records.id}`)} />
                            <DeleteButton modal_id="#userDeleteModal" action={() => setUserId(records.id)} />
                        </div>
                    </>)
                }
            }
        ]
    })

    useEffect(() => {
        initialFormState("accountCreateForm")
        setData({})
        reloadUrlDataTable(dt, `${ACCOUNT_LIST}?type=${accountType}`)
    }, [dt, accountType])

    const handleChangeStatus = (status, id) => {
        fetchData(ACCOUNT_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            loadDataTable(dt)
        })
    }

    const editUser = (data) => {
        setData({ id: data.id, name: data.name, email: data.email })
    }

    const createAccount = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('accountCreateForm'))
            formdata.append('role', accountType)
            if (data.id) formdata.append('user_id', data.id)
            fetchData(data.id ? ACCOUNT_UPDATE : ACCOUNT_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    initialFormState("accountCreateForm")
                    loadDataTable(dt)
                    setData({})
                }
            })
        }
    }

    return (
        <Container fluid>
            <BackendLayout title={_.startCase(accountType)}>
                <Row>
                    <Col md={5} lg={3} xs={12}>
                        <div className="cardGray text-center p-4">
                            <div className="createScheduleBtn">
                                {data.id ? "Update User" : "Create User"}
                            </div>
                            <form id="accountCreateForm" action="#" method="post" className="needs-validation" noValidate>
                                <InputField name="name" value={data && data.name} required />
                                <InputField name="email" value={data && data.email} disabled={data.id ? true : false} required />
                                <PasswordField name="password" required={data.id ? false : true} />
                            </form>

                            <div className="scheduleTimeLeftBottomBtnFixed">
                                <button className="createScheduleBtnNew" onClick={createAccount}>
                                    <ButtonSpinner load={tinyloader} btnName={data.id ? "Update User" : "Create User"} />
                                </button>
                                <button className="createScheduleBtnNew mt-2" onClick={() => { setData({}); initialFormState("accountCreateForm"); }}>
                                    Cancle
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col md={7} lg={9} xs={12}>
                        <Datatables dt_name="account_list" />
                    </Col>
                </Row>
                <ConfirmationModal modal_id="userDeleteModal" title="Delete User" action={() => handleChangeStatus('delete', userId)}>
                    <p className="">Are you sure want to delete this user?</p>
                </ConfirmationModal>
            </BackendLayout>
        </Container>

    )
}

export default AccountList