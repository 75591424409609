import { useContext, useEffect, useState } from "react";
import _ from 'lodash';
import React from "react";
import ChartComponant from '../../components/ChartComponant';
import { fetchData } from '../../components/Helper';
import { DASHBOARD_STATS } from '../../components/ApiRoutes';
import Context from '../../components/Context';
import { useNavigate } from "react-router-dom";
import { Chart } from 'react-charts'
import MyChart from "../../components/chart";

const Dashboard = () => {
  const [context] = useContext(Context)
  const navigate = useNavigate()

  const [dashboardData, setDashboardData] = useState()
  const [bgColor] = useState([
    'rgba(255, 99, 132, 0.7)',
    'rgba(255, 159, 64, 0.7)',
    'rgba(255, 205, 86, 0.7)',
    'rgba(75, 192, 192, 0.7)',
    'rgba(54, 162, 235, 0.7)',
    'rgba(153, 102, 255, 0.7)',
    'rgba(201, 203, 207, 0.7)'
  ])

  const data = React.useMemo(
    () => [
      {
        label: 'Series 1',
        data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
      },
      {
        label: 'Series 2',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
      }
    ],
    []
  )

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  )

  const [dateRange] = useState(['', ''])
  const [startDate, endDate] = dateRange

  const coachRecord = {
    labels: dashboardData && dashboardData.coachRecord ? Object.keys(dashboardData.coachRecord) : '',
    datasets: [{
      label: 'Coach',
      data: dashboardData && dashboardData.coachRecord ? Object.values(dashboardData.coachRecord) : '',
      backgroundColor: _.shuffle(bgColor),
      hoverOffset: 4
    }]
  };

  const trainingProviderRecord = {
    labels: dashboardData && dashboardData.trainingProviderRecord ? Object.keys(dashboardData.trainingProviderRecord) : '',
    datasets: [{
      label: 'Training Provider',
      data: dashboardData && dashboardData.trainingProviderRecord ? Object.values(dashboardData.trainingProviderRecord) : '',
      backgroundColor: _.shuffle(bgColor),
      hoverOffset: 4
    }]
  };

  const facilityProviderRecord = {
    labels: dashboardData && dashboardData.facilityProviderRecord ? Object.keys(dashboardData.facilityProviderRecord) : '',
    datasets: [{
      label: 'Facility Provider',
      data: dashboardData && dashboardData.facilityProviderRecord ? Object.values(dashboardData.facilityProviderRecord) : '',
      backgroundColor: _.shuffle(bgColor),
      hoverOffset: 4
    }]
  };

  const userRecord = {
    labels: dashboardData && dashboardData.userRecord ? Object.keys(dashboardData.userRecord) : '',
    datasets: [{
      label: 'User',
      data: dashboardData && dashboardData.userRecord ? Object.values(dashboardData.userRecord) : '',
      backgroundColor: _.shuffle(bgColor),
      hoverOffset: 4
    }]
  };

  const scales = {

    x: {
      grid: {
        color: 'transparent',
        borderColor: 'transparent'
      },
      ticks: {
        display: false,
      }
    },
    y: {
      beginAtZero: true,
      grid: {
        color: 'transparent',
        borderColor: 'transparent'
      },
      ticks: {
        display: false,
        crossAlign: 'far',
      }
    }
  }

  const plugins = {
    legend: {
      display: false,
      position: 'top',
    },
    label: {
      display: false
    },
  }

  useEffect(() => {
    fetchData(`${DASHBOARD_STATS}?startDate=${startDate}&endDate=${endDate}`, 'GET', '', true, false, (res) => {
      if (res.status) {
        setDashboardData(res.data)
      }
    })
  }, [startDate, endDate]);

  return (
    <section className="admin-wrapper">
      <div className="container-fluid pe-0">
        <div className="card-new h-100 p-4">
          <div className="row justify-content-between">
            <h2 className="page-title">Dashboard</h2>
          </div>
          <div className="row">
            <div className="col-12 me-auto">
              <div className="row g-3 g-xl-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3">
                <div className="col adb-mt-19">
                  <div className="card h-100 border-0  bg-gradient-danger  rounded-15 h-100" role="button" onClick={() => navigate(`/user-management/coach/accounts`)}>
                    <div className="card-body p-4 d-flex align-items-center">
                      {/* <img src='images/circle.svg' className='loginBgImg' /> */}
                      <div className="d-flex">
                        <img src='images/Icon.svg' />
                        <div className="d-block flex-wrap justify-content-between align-items-center ms-5 cardDetails">
                          <h2 className="m-0 text-dark">{dashboardData ? dashboardData.totalCoach : ''}</h2>

                          {/* <div className="ratio ratio-1x1 mmw-110px">
                                                    <ChartComponant key="1" id="coach_chart" plugins={plugins} label={coachRecord.labels} scale={scales} data={coachRecord} type="pie" />
                                                </div> */}
                          <h4 className="text-dark fw-normal mt-3">Coach
                            {/* <i class="bi bi-outlet float-right" role="img" aria-label="Person"></i> */}
                          </h4>
                          <p className="mb-0 cardBlueTxt">Lorem Ipsum</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col adb-mt-19">
                  <div className="card h-100 border-0  bg-gradient-info  rounded-15 h-100" role="button" onClick={() => navigate(`/user-management/training-provider/accounts`)}>
                    <div className="card-body p-4 d-flex align-items-center">
                      <div className="d-flex">
                        <img src='images/greenIcon.svg' />
                        {/* <img src='images/circle.svg' className='loginBgImg' /> */}
                        <div className="d-block flex-wrap justify-content-between align-items-center ms-5 cardDetails">
                          <h2 className="m-0 text-white">{dashboardData ? dashboardData.totalTrainingProvider : ''}</h2>
                          <h4 className="text-white fw-normal mt-3">Training Provider
                            {/* <i class="bi bi-globe float-right" role="img" aria-label="Person"></i> */}
                            {/* <i class="fa fa-futbol-o" aria-hidden="true"></i> */}
                          </h4>
                          <p className="mb-0 cardBlueTxt">Lorem Ipsum</p>
                          {/* <div className="ratio ratio-1x1 mmw-110px">
                                                        <ChartComponant key="2" id="training_provider_chart" plugins={plugins} label={trainingProviderRecord.labels} scale={scales} data={trainingProviderRecord} type="pie" />
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col adb-mt-19">
                  <div className="card border-0 shadow-sm bg-gradient-success rounded-10 h-100" role="button" onClick={() => navigate(`/user-management/facility-provider/accounts`)}>
                    <div className="card-body p-4 d-flex align-items-center">
                      <div className="d-flex">
                        <img src='images/blueIcon.svg' />
                        {/* <img src='images/circle.svg' className='loginBgImg' /> */}
                        <div className="d-block flex-wrap justify-content-between align-items-center ms-5 cardDetails">
                          <h2 className="m-0 text-white">{dashboardData ? dashboardData.totalFacilityProvider : ''}</h2>
                          <h4 className="text-white fw-normal mt-3">Facility Provider
                            {/* <i class="bi bi-globe float-right" role="img" aria-label="Person"></i> */}
                            {/* <i class="fa fa-futbol-o" aria-hidden="true"></i> */}
                          </h4>
                          <p className="mb-0 cardBlueTxt">Lorem Ipsum</p>
                          {/* <div className="ratio ratio-1x1 mmw-110px">
                                                        <ChartComponant key="2" id="training_provider_chart" plugins={plugins} label={trainingProviderRecord.labels} scale={scales} data={trainingProviderRecord} type="pie" />
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col adb-mt-20 mt-5">
                  <div className="card border-0 bg-gradient-client shadow-sm rounded-10 h-100" role="button" onClick={() => navigate(`/user-management/user/accounts`)}>
                    <div className="card-body p-4 d-flex align-items-center">
                      <div className="d-flex">
                        <img src='images/user.svg' />
                        {/* <img src='images/circle.svg' className='loginBgImg' /> */}
                        <div className="d-block flex-wrap justify-content-between align-items-center ms-5 cardDetails">
                          <h2 className="m-0 text-white">000</h2>
                          <h4 className="text-white fw-normal mt-3">Client
                            {/* <i class="bi bi-globe float-right" role="img" aria-label="Person"></i> */}
                            {/* <i class="fa fa-futbol-o" aria-hidden="true"></i> */}
                          </h4>
                          <p className="mb-0 cardBlueTxt">Lorem Ipsum</p>
                          {/* <div className="ratio ratio-1x1 mmw-110px">
                                                        <ChartComponant key="2" id="training_provider_chart" plugins={plugins} label={trainingProviderRecord.labels} scale={scales} data={trainingProviderRecord} type="pie" />
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col adb-mt-20 mt-5">
                  <div className="card border-0 bg-gradient-green shadow-sm rounded-10 h-100" role="button" onClick={() => navigate(`/user-management/user/accounts`)}>
                    <div className="card-body p-4 d-flex align-items-center">
                      <div className="d-flex">
                        <img src='images/user.svg' />
                        {/* <img src='images/circle.svg' className='loginBgImg' /> */}
                        <div className="d-block flex-wrap justify-content-between align-items-center ms-5 cardDetails">
                          <h2 className="m-0 text-white">{dashboardData ? dashboardData.totalUser : ''}</h2>
                          <h4 className="text-white fw-normal mt-3">User
                            {/* <i class="bi bi-globe float-right" role="img" aria-label="Person"></i> */}
                            {/* <i class="fa fa-futbol-o" aria-hidden="true"></i> */}
                          </h4>
                          <p className="mb-0 cardBlueTxt">Lorem Ipsum</p>
                          {/* <div className="ratio ratio-1x1 mmw-110px">
                                                        <ChartComponant key="2" id="training_provider_chart" plugins={plugins} label={trainingProviderRecord.labels} scale={scales} data={trainingProviderRecord} type="pie" />
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col adb-mt-20 mt-5">
                  <div className="card border-0 bg-gradient-tournament shadow-sm rounded-10 h-100" role="button" onClick={() => navigate(`/user-management/user/accounts`)}>
                    <div className="card-body p-4 d-flex align-items-center">
                      <div className="d-flex">
                        <img src='images/user.svg' />
                        {/* <img src='images/circle.svg' className='loginBgImg' /> */}
                        <div className="d-block flex-wrap justify-content-between align-items-center ms-5 cardDetails">
                          <h2 className="m-0 text-white">23</h2>
                          <h4 className="text-white fw-normal mt-3">Tournament
                            {/* <i class="bi bi-globe float-right" role="img" aria-label="Person"></i> */}
                            {/* <i class="fa fa-futbol-o" aria-hidden="true"></i> */}
                          </h4>
                          <p className="mb-0 cardBlueTxt">Lorem Ipsum</p>
                          {/* <div className="ratio ratio-1x1 mmw-110px">
                                                        <ChartComponant key="2" id="training_provider_chart" plugins={plugins} label={trainingProviderRecord.labels} scale={scales} data={trainingProviderRecord} type="pie" />
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Dashboard