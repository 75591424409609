import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Context from '../../components/Context'
import { ButtonSpinner, fetchData, scrollbarSetToTop, showAlert, showPassword, validateForm } from '../../components/Helper'
import FacebookLogin from 'react-facebook-login';
import jwt_decode from "jwt-decode";
import { ROLE_LIST, REGISTER } from '../../components/ApiRoutes';

const Register = () => {

    const navigate = useNavigate()
    const [context, setContext] = useContext(Context)
    const [token] = useState(localStorage.getItem('playzoon-token'))
    const [tinyloader, setTinyloader] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [role, setRole] = useState(2);
    const [loginType, setLoginType] = useState("google");
    const google = window.google;

    useEffect(() => {
        scrollbarSetToTop();
        if (token && context && context.auth) {
            navigate("/dashboard");
        }
        let block_user = localStorage.getItem('block_user')
        if (block_user) {
            localStorage.removeItem('block_user')
            showAlert(JSON.parse(block_user))
        }
    }, [token, context, navigate])

    useEffect(() => {
        fetchData(ROLE_LIST, 'GET', '', false, false, (res) => {
            if (res.status) {
                setRoleList(res.data)
            }
        })
    }, [])

    useEffect(() => {
        if (google) {
            google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_APP_ID,
                callback: submitRegisterForm
            })

            google.accounts.id.renderButton(
                document.getElementById('signInDiv'),
                { theme: "outline", size: "large" }
            )
            google.accounts.id.prompt();
        }
    }, [google, loginType, role])

    const submitRegisterForm = (e, byBtn = false) => {

        let formdata = {}
        if (byBtn) {
            if (!validateForm(e)) return
            formdata = new FormData(document.getElementById('register-form'));
        } else {
            if (!role) {
                showAlert({ status: false, message: "Please select a role type." })
                return
            }
            if (loginType === 'google') {
                var googleUserDetail = jwt_decode(e.credential)
                if (googleUserDetail)
                    formdata = { name: googleUserDetail.name, email: googleUserDetail.email, role_id: role, login_type: 'google' }
            } else {
                if (e.status && e.status === 'unknown') {
                    showAlert({ status: false, message: "You are not able to login." })
                    return
                }
                formdata = { name: e.name, email: e.email ?? null, facebook_user_id: e.userID, role_id: role, login_type: 'facebook' }
            }
        }

        setTinyloader(true)

        fetchData(REGISTER, 'POST', formdata, false, byBtn, (res) => {
            setTinyloader(false)

            if (res.status) {
                if (res.data && res.data.access_token) {
                    localStorage.setItem("playzoon-token", res.data.access_token);

                    setContext(prevState => ({
                        ...prevState,
                        auth: res.data,
                    }))
                    navigate("/dashboard");
                } else {
                    setTimeout(() => navigate("/"), 1000)
                }
            }
        })
    }

    return (
        <section className="login-section">
            <div className="container">
                <div>
                    <img src='images/circle.svg' alt="Logo" className='loginBgImg' />
                </div>
                <div className="row g-0 rounded-10 overflow-hidden">
                    <div className="col-md-6 col-lg-5 bg-white align-items-center">
                        <div className='loginCardDiv'>
                            <div className='text-center mt-4'>
                                <h4 className="heading-lines headingMargin mb-4 mt-0">Sign Up</h4>
                            </div>
                            <div className="form-floating px-5">
                                <select className="form-select loginSelectBox" aria-label="Default select example" name="role" value={role} onChange={(e) => setRole(e.target.value)}>
                                    <option value="">Select</option>
                                    {roleList && roleList.length > 0 && roleList.map((role, key) => <option value={role.id} key={key}>{role.name}</option>)}
                                </select>
                            </div>
                            <div className='d-flex m-auto justify-content-center'>
                                <div className="d-flex fbBtnDiv justify-content-between pb-3">
                                    <FacebookLogin
                                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                        fields="name,email,picture"
                                        onClick={() => setLoginType("facebook")}
                                        callback={submitRegisterForm}
                                        textButton="Facebook"
                                        icon="fa-facebook"
                                    />
                                </div>
                                <div className="mb-4 ms-5" id="signInDiv" >
                                    <button type="button" className="googleBtn btn-lg w-100" disabled={tinyloader} onClick={() => setLoginType("google")} >
                                        <i class="fa fa-google"></i>
                                        <ButtonSpinner load={tinyloader} btnName="Google" />
                                    </button>
                                </div>
                            </div>
                            <div className='OrlineDiv px-5 text-center position-relative'>
                                <span className='orTxt'>Or</span>
                            </div>
                            <form className="p-4 p-sm-5 w-100 needs-validation" noValidate id="register-form">
                                <div className="form-floating mb-3">
                                    <span className='loginLabelSpan'>Select</span>
                                    <select className="form-select loginSelectBox" aria-label="Default select example" id="role_id" name="role_id" required>
                                        <option value="">Select</option>
                                        {roleList && roleList.length > 0 && roleList.map((role, key) => <option value={role.id} key={key}>{role.name}</option>)}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="name" className='loginLabelSpan'>Name</label>
                                    <input type="text" className="form-control loginSelectBox" id="name" name="name" placeholder="Enter Name" required />
                                </div>
                                <div className=" mb-3">
                                    <label htmlFor="floatingEmail" className='loginLabelSpan'>Email</label>
                                    <input type="text" className="form-control loginSelectBox" id="floatingEmail" name="email" placeholder="name@example.com" required />
                                </div>
                                <div className="mb-3 position-relative">
                                    <label htmlFor="floatingPassword" className='loginLabelSpan'>Password</label>
                                    <input type="password" className="form-control no-validate-icon loginSelectBox" id="floatingPassword" name="password" placeholder="*********" autoComplete='off' required />
                                    <button type="button" onClick={(e) => showPassword(e, "floatingPassword")} className="position-absolute me-3 top-0 end-0 pt-3 mt-4">
                                        <i className="bi-eye fs-5 pe-none"></i>
                                    </button>
                                </div>
                                <div className="mb-4">
                                    <button type="submit" className="btn btn-blue btn-lg w-100" onClick={(e) => submitRegisterForm(e, true)} disabled={tinyloader}>
                                        <ButtonSpinner load={tinyloader} btnName="Register" />
                                    </button>
                                </div>
                                <Link to="/login" className="anchor-blueorange fw-medium">Login</Link>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-5 ms-auto align-self-center'>
                        <div>
                            <img src='images/signUpImage.svg' alt='Register' className='w-100' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Register
