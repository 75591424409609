import { useEffect, useState } from "react";
import Datatables, { reloadUrlDataTable } from '../../components/Datatables';
import { LOG_URL } from '../../components/ApiRoutes';

function Systemlogs() {
    const [dt] = useState({
        dt_url: `${LOG_URL}`,
        dt_name: 'log_list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false  },
            { data: "action_perform_id", name: 'action_perform_id', title: "Action Perform ID" },
            { data: "action_perform_by", name: 'action_perform_by', title: "Action Perform By" },  
            { data: "action_perform_on", name: 'action_perform_on', title: "Action Perform On" },
            { data: "message", name: 'message', title: "Description" },  
            { data: "created_at", name: 'created_at', title: "Log Time" },
           
        ],
    })

    useEffect(() => {
        reloadUrlDataTable(dt, `${LOG_URL}`)
    }, [dt])


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">Activity Logs</h6>
                        </div>
                      
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="log_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Systemlogs;